import React from "react"
import { Link, graphql } from "gatsby"

import { Layout } from "../../components/Layout"

export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          availableForSale
          title
          shopifyId
          description
          handle
          priceRange {
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`

const ProductsPage = ({ data }) => {
  return (
    <Layout>
      <section className="container">
        <h1>Products</h1>
        <ul>
          {data.allShopifyProduct.edges
            .filter(({ node }) => node.availableForSale)
            .map(({ node }) => (
              <li key={node.shopifyId}>
                <h3>
                  <Link to={`/product/${node.handle}`}>{node.title}</Link>
                  {" - "}${node.priceRange.minVariantPrice.amount}
                </h3>
                <p>{node.description}</p>
              </li>
            ))}
        </ul>
      </section>
    </Layout>
  )
}

export default ProductsPage
